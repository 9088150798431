<template>
  <div class="doctor_consult_form_main">
    <div class="page_main_header_section pb-3">
      <div class="row">
        <div class="col-auto pr-0">
          <router-link class="btn back_btn" :to="{ name: 'DashboardMain' }">
            <i aria-hidden="true" class="fa fa-chevron-left"></i>
          </router-link>
        </div>
        <div class="col pl-0">
          <h3 class="page_main_heading"> Consult Services</h3>
          <p class="page_main_h_description">This form is meant for professionals only.</p>
        </div>
      </div>
    </div>
    <div class="doctor_consult_form_body">
      <template v-if="isContentReady">
        <template v-if="scheduleInfo && !scheduleInfo.is_active">
          <doctorWaitingForVerification></doctorWaitingForVerification>
        </template>
        <template v-else>
          <form @submit.prevent="consultFormBeforeSubmitMethod" autocomplete="off">
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6 mr-auto">
                  <label>I have language proficiency in <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6 ml-auto">
                  <multiselect tag-placeholder="Add this as new tag" v-model="languageProficiencyValue" placeholder=""
                    label="name" track-by="code" :options="languageProficiencyLabel" :option-height="104" :multiple="true"
                    :taggable="false" v-validate="'required'"
                    v-bind:class="{ 'is-invalid': errors.has('languageProficiencyValue') }" name="languageProficiencyValue"
                    data-vv-as="language">
                  </multiselect>
                  <small class="invalid-feedback" v-if="errors.has('languageProficiencyValue')">{{
                    errors.first('languageProficiencyValue') }}</small>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>Registration/License Number <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                  <div class="license_country_list_wrp">
                    <template v-if="updateFormState">
                      <div class="vue-tel-input disabled" @click="notEditableFieldsPopupOpenMethod">
                        <div class="vti__dropdown"
                          :class="open ? 'open' : ''" :disabled="updateFormState">
                          <span class="vti__selection">
                            <div class="vti__flag" v-bind:class="license.countryIso"></div>
                            <span class="vti__dropdown-arrow"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                          </span>
                        </div>
                        <div class="phone_number_field_box">
                          <label class="disabled">{{ license.number }}</label>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="vue-tel-input">
                        <div class="vti__dropdown" v-click-outside="clickedOutside" @click="toggleDropdown"
                          :class="open ? 'open' : ''">
                          <span class="vti__selection">
                            <div class="vti__flag" v-bind:class="license.countryIso"></div>
                            <span class="vti__dropdown-arrow"><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
                          </span>
                          <ul class="vti__dropdown-list" v-if="open">
                            <li v-on:click="chooseCountry(country)" class="vti__dropdown-item"
                              v-for="country in allCountriesList" :key="country.iso2">
                              <div class="vti__flag" v-bind:class="country.iso2"></div>
                              &nbsp;&nbsp;<strong>‬‎{{ country.name }}</strong>&nbsp; <span>+{{ country.dialCode }}</span>
                            </li>
                          </ul>
                        </div>
                        <div class="phone_number_field_box">
                            <input autocomplete="off" name="license.number" type="text" class="form-control"
                              v-model="license.number" v-validate="'required'"
                              v-bind:class="{ 'is-invalid': errors.has('license.number') }" data-vv-as="license number"> 
                        </div>
                      </div>
                    </template>
                  </div>              
                  <small class="invalid-feedback" v-if="errors.has('license.number')">{{ errors.first('license.number')}}</small>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>Registration Portal <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                  <template v-if="updateFormState">
                    <label class="disabled" @click="notEditableFieldsPopupOpenMethod">
                      {{ registrationPortal }}
                     </label>
                  </template>
                  <template v-else>
                    <template v-if="uploadLicenseFileName">
                      <input type="text" class="form-control" v-model="registrationPortal" name="registrationPortal" autocomplete="off">
                    </template>
                    <template v-else>
                      <input type="text" class="form-control" v-model="registrationPortal" name="registrationPortal"
                        v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('registrationPortal') }"
                        data-vv-as="registration" autocomplete="off">
                      <small class="invalid-feedback" v-if="errors.has('registrationPortal')">{{
                        errors.first('registrationPortal') }}</small>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 ml-auto">
                  <label class="mb-0" style="color: #ffd400;font-size: 18px;line-height: 1.3;text-align:justify;">Please
                    paste the
                    hyperlink to the online portal/website/database that shows your license number or registration
                    with a medical council
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-sm-6 ml-auto">
                  <label style="display: block;margin: 0;">OR</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>License/Certificate Document</label>
                </div>
                <div class="col-sm-6">
                  <div class="upload_attachment_btn">
                    <template v-if="updateFormState">
                      <label for="upload_attachment" :disabled="updateFormState" @click="notEditableFieldsPopupOpenMethod">
                        <input type="file" id="upload_attachment" name="file" ref="uploadLicenseFile" accept=".pdf, image/jpeg, image/png"
                          tabindex="3" hidden :disabled="updateFormState"/>
                        <span class="upload_attachment_btn">
                          <template v-if="!this.uploadLicenseFileName"><i class="fa fa-upload mr-2" aria-hidden="true"></i>
                            Upload Document</template>
                          <template v-if="this.uploadLicenseFileName">{{ this.uploadLicenseFileName }}</template>
                        </span>
                      </label>
                    </template>
                    <template v-else>
                      <label for="upload_attachment">
                        <input type="file" id="upload_attachment" name="file" ref="uploadLicenseFile" accept=".pdf, image/jpeg, image/png"
                          tabindex="3"  @change="handleUploadLicenseFile" hidden/>
                        <span class="upload_attachment_btn">
                          <template v-if="!this.uploadLicenseFileName"><i class="fa fa-upload mr-2" aria-hidden="true"></i>
                            Upload Document</template>
                          <template v-if="this.uploadLicenseFileName">{{ this.uploadLicenseFileName }}</template>
                        </span>
                      </label>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>E-Signed Signature <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                  <div class="upload_attachment_btn">
                    <template v-if="updateFormState">
                      <label for="upload_esigned" :disabled="updateFormState" @click="notEditableFieldsPopupOpenMethod">
                        <input type="file" id="upload_esigned" name="uploadESignedLicenseFile" ref="uploadESignedLicenseFile" accept=".pdf, image/jpeg, image/png" hidden :disabled="updateFormState"/>
                        <span class="upload_attachment_btn">
                          <template v-if="!this.uploadESignedLicenseFileName"><i class="fa fa-upload mr-2" aria-hidden="true"></i>
                            Upload Document</template>
                          <template v-if="this.uploadESignedLicenseFileName">{{ this.uploadESignedLicenseFileName }}</template>
                        </span>
                      </label>
                    </template>
                    <template v-else>
                      <label for="upload_esigned">
                        <input type="file" id="upload_esigned" name="uploadESignedLicenseFile" ref="uploadESignedLicenseFile" accept=".pdf, image/jpeg, image/png"  @change="handleUploadESignedLicenseFile" hidden v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('consultFees') }"  data-vv-as="e signed"/>
                        <span class="upload_attachment_btn">
                          <template v-if="!this.uploadESignedLicenseFileName"><i class="fa fa-upload mr-2" aria-hidden="true"></i>
                            Upload E-Signed</template>
                          <template v-if="this.uploadESignedLicenseFileName">{{ this.uploadESignedLicenseFileName }}</template>
                        </span>
                      </label>
                       <small class="invalid-feedback d-block" v-if="errors.has('uploadESignedLicenseFile')">{{ errors.first('uploadESignedLicenseFile')
                        }}</small>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>Hospital/Institute/Clinic/Independent <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                <input type="text" class="form-control" v-model="organization" v-validate="'required'"
                  v-bind:class="{ 'is-invalid': errors.has('organization') }" name="organization"
                  data-vv-as="organization">
                  <small class="invalid-feedback" v-if="errors.has('organization')">{{ errors.first('organization') }}</small>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>Consult Fees <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-6">
                      <input type="number" class="form-control" v-model="consultFees" name="consultFees"
                        v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('consultFees') }"
                        data-vv-as="fees">
                      <small class="invalid-feedback" v-if="errors.has('consultFees')">{{ errors.first('consultFees')
                        }}</small>
                    </div>
                    <div class="col-6 pl-0">
                      <multiselect v-model="currencyVal" placeholder="" :options="currencyListLabel" :option-height="104"
                        :multiple="false" label="name" track-by="code" :taggable="false" v-validate="'required'"
                        v-bind:class="{ 'is-invalid': errors.has('currencyVal') }" name="currencyVal" data-vv-as="currency">
                      </multiselect>
                      <small class="invalid-feedback" v-if="errors.has('currencyVal')">{{ errors.first('currencyVal')
                        }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-2 row">
              <div class="col-sm-6">
                <label>My Bio <span class="star_required">*</span></label>
              </div>
              <div class="col-sm-6">
                <textarea class="form-control" v-model="about_doctor" rows="4" v-validate="'required'"
                  v-bind:class="{ 'is-invalid': errors.has('about_doctor') }" name="about_doctor"
                  data-vv-as="My Bio"></textarea>
                <small class="invalid-feedback" v-if="errors.has('about_doctor')">{{ errors.first('about_doctor') }}</small>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6 ml-auto">
                <label class="mb-0" style="color: #ffd400;font-size: 18px;line-height: 1.3;text-align:justify;">Please
                  write a quick introduction about yourself for LBIL members to know you. You may include your experience,
                  education, or anything you deem is important for your incoming patients, non-patients, and caregivers to
                  know. Max
                  500 words
                </label>
              </div>
            </div>
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-sm-6">
                  <label>Specializations <span class="star_required">*</span></label>
                </div>
                <div class="col-sm-6">
                  <template v-if="updateFormState">
                    <label class="disabled" @click="notEditableFieldsPopupOpenMethod">{{ category[0].display_name }}</label>
                  </template>
                  <template v-else>
                    <multiselect v-model="category" placeholder="" :options="categoryList" :option-height="104"
                      :multiple="false" :taggable="false" label="display_name" track-by="filter_key" v-validate="'required'"
                      v-bind:class="{ 'is-invalid': errors.has('category') }" name="category"
                      data-vv-as="specializations">
                    </multiselect>
                    <small class="invalid-feedback" v-if="errors.has('category')">{{ errors.first('category') }}</small>
                  </template>
                </div>
              </div>
            </div>
            <div class="extra_optional_keys">
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label>Experience <span class="star_required">*</span></label>
                  </div>
                  <div class="col-sm-6">
                  <input type="text" class="form-control" v-model="experience" v-validate="'required'"
                    v-bind:class="{ 'is-invalid': errors.has('experience') }" name="experience"
                    data-vv-as="experience">
                   <small class="invalid-feedback" v-if="errors.has('experience')">{{ errors.first('experience') }}</small>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label>Education</label>
                  </div>
                  <div class="col-sm-6">
                    <textarea class="form-control" v-model="education"></textarea>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label>Other Specializations</label>
                  </div>
                  <div class="col-sm-6">
                    <textarea class="form-control" v-model="other_specialization"></textarea>
                    <!-- <multiselect v-model="other_specialization" placeholder="" :options="otherCategoryList" :option-height="104"
                      :multiple="true" :taggable="true" @tag="addNewCustomCategory" label="display_name" track-by="filter_key" v-validate="'required'"
                      v-bind:class="{ 'is-invalid': errors.has('other_specialization') }" name="other_specialization"
                      data-vv-as="other specializations">
                    </multiselect>
                    <small class="invalid-feedback" v-if="errors.has('other_specialization')">{{ errors.first('other_specialization') }}</small> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="weekly_wrapper_box">
                <p class="heading_f_weekly_slots">Please confirm your available time slots every week for LBIL users to see.</p>
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label class="w_slots_blurb">You have the control to customize your slots and change the timings depending on your availability per week. However, please note that if you do not add additional weeks, i.e., week two, week three, or onwards, then your last week's slots will be shown until you change your schedule when needed by adding a new week section.</label>
                  </div>
                  <div class="col-sm-6">
                    <dayWiseSlots v-model="weeklySchedule"></dayWiseSlots>
                    <input type="text" class="form-control hiddenField" v-model="weeklySchedulestr" name="weeklySchedulestr"
                        v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('weeklySchedulestr') }">
                    <small class="invalid-feedback mt-3 text-center" v-if="!weeklySchedulestr && errors.has('weeklySchedulestr')">At least one slot must be added to the schedule</small>
                    <small class="d-block invalid-feedback  mt-3 text-center" v-if="showErrorsTimes">Please add correct timings for your schedule.</small>
                  </div>
                </div>
                <div class="row align-items-center mt-4">
                  <div class="col-auto"><label class="w_slots_exmp">Example:</label></div>
                  <div class="col"><p class="w_slots_exmp_txt">For Week One, I have added Wednesdays (2:00 pm—4:00 pm) and Thursdays (11:00 am—2:00 pm) as my slots and do not add additional weeks to my schedule. This will be the shown timings on LBIL until I add a "new week" with the updated days and slots to notify that there is a change of timing in my availability</p></div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="accept_terms_label">
                <span class="checkbox"><input title="Please tick" v-model="user_agreement" name="user_agreement"
                    type="checkbox" v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('user_agreement') }"
                    data-vv-as="user agreement" class="hiddenField" /><span><i class="fa fa-check" aria-hidden="true"></i></span>
                </span>
                <span class="accept_terms_text"> I have read and agree to the terms and conditions mentioned in the
                  <router-link to="/user/agreement/" target="_blank">user agreement</router-link>.
                </span>
              </label>
              <small class="invalid-feedback" v-if="errors.has('user_agreement')">{{ errors.first('user_agreement')
                }}</small>
            </div>
            <div class="form-group">
              <label class="accept_terms_label">
                <span class="checkbox"><input title="Please tick" v-model="onlineTeleconsulting" name="onlineTeleconsulting"
                    type="checkbox" v-validate="'required'"
                    v-bind:class="{ 'is-invalid': errors.has('onlineTeleconsulting') }" data-vv-as="online consulting"
                    class="hiddenField" /><span><i class="fa fa-check" aria-hidden="true"></i></span>
                </span>
                <span class="accept_terms_text"> I understand and agree to be a professional teleconsultant for LBIL which
                  is also a part of Lymphomap's services.
                </span>
              </label>
              <small class="invalid-feedback" v-if="errors.has('onlineTeleconsulting')">{{
                errors.first('onlineTeleconsulting')
              }}</small>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <button type="submit" class="btn btn-primary" :disabled="submitted">Submit</button>
              </div>
            </div>
          </form>
        </template>
      </template>
      <div class="compontent-loader" style="padding-top: 30svh;" v-else>
        <div class="spinner"></div>
      </div>
    </div>
    <profileUpdatFieldsPopup v-if="profileUpdatePopupState"></profileUpdatFieldsPopup>
    <notEditableFieldsPopup v-if="notEditableFieldsPopupState"></notEditableFieldsPopup>
  </div>
</template>
<script>
/*eslint-disable */

import dayWiseSlots from '@/components/private/doctor-schedule-form/dayWiseSlots/';
import doctorWaitingForVerification from '@/components/private/doctor-waiting-for-verification/';
import profileUpdatFieldsPopup from '@/components/private/profile-update-fields-popup/';
import notEditableFieldsPopup from '@/components/private/doctor-schedule-form/notEditableFieldsPopup.vue';

import moment from 'moment';
import 'vue-datetime/dist/vue-datetime.css'
import toast from '@/services/toast';
import { Datetime } from 'vue-datetime';
import Multiselect from 'vue-multiselect'
import allCountries from '@/services/phoneCodeList.json';
import {mapGetters} from 'vuex';
import {bus} from '@/main';

export default {
  components: {
    Multiselect,
    datetime: Datetime,
    dayWiseSlots,
    doctorWaitingForVerification,
    profileUpdatFieldsPopup,
    notEditableFieldsPopup
  },
  data() {
    return {
      submitted: false,
      availableDayLabel: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
      languageProficiencyLabel: [
        { name: 'English', code: 'English' },
        { name: 'French', code: 'French' },
        { name: 'Hindi', code: 'Hindi' },
        { name: 'Mandarin', code: 'Mandarin' },
        { name: 'German', code: 'German' },
        { name: 'Japanese', code: 'Japanese' },
        { name: 'Korean', code: 'Korean' },
        { name: 'Italian', code: 'Italian' },
        { name: 'Cantonese', code: 'Cantonese' },
        { name: 'Spanish', code: 'Spanish' },
        { name: 'Bengali', code: 'Bengali' },
        { name: 'Tamil', code: 'Tamil' },
        { name: 'Gujrati', code: 'Gujrati' },
        { name: 'Marathi', code: 'Marathi' },
        { name: 'Telegu', code: 'Telegu' },
        { name: 'Malayalam', code: 'Malayalam' },
        { name: 'Punjabi', code: 'Punjabi' },
        { name: 'Thai', code: 'Thai' },
        { name: 'Hebrew', code: 'Hebrew' },
        { name: 'Arabic', code: 'Arabic' },
        { name: 'Swahili', code: 'Swahili' },
        { name: 'Amharic', code: 'Amharic' },
        { name: 'Russian', code: 'Russian' },
        { name: 'Bahasa', code: 'Bahasa' },
        { name: 'Malay', code: 'Malay' },
        { name: 'Portugese', code: 'Portugese' },
      ],
      weeklyDayTimesValue: [],
      availableDayValue: [],
      availableDaySelectedArrValue: [],
      languageProficiencyValue: [],
      sendLanguageProficiencyValue: [],
      start_time: '',
      end_time: '',
      timeDiffValidation: false,
      allCountriesList: [],
      license: {
        number: '',
        isValid: false,
        countryIso: '',
        countryCode: ''
      },
      registrationPortal: '',
      uploadLicenseFile: null,
      uploadLicenseFileName: '',
      uploadESignedLicenseFile: null,
      uploadESignedLicenseFileName: '',
      onlineTeleconsulting: false,
      user_agreement: false,
      disabledSomeFields: true,
      consultFees: '',
      currencyListLabel: [{code: "USD", name: "US Dollar"}],
      currencyVal:[],
      about_doctor: '',
      blurOfLbilConsultPopupState: true,
      open: false,
      categoryList: [],
      category:[],
      weeklySchedule: {},
      weeklySchedulestr:'',
      scheduleInfo: null,
      updateFormState:false,
      isContentReady:false,
      experience:'',
      education:'',
      other_specialization:[],
      otherCategoryList: [],
      profileUpdatePopupState:false,
      notEditableFieldsPopupState:false,
      organization:'',
      showErrorsTimes: false,
    }
  },
  computed: {
    ...mapGetters([
      'selfUserDetailGetters',
    ]),
    getSelfUsrInfoMethod() {
      return this.selfUsrInfoState = this.$store.getters.selfUserDetailGetters.data
    },
  },
  mounted() {
    this.$store.dispatch('getDoctorCategoriesAction').then((res) => {
      this.categoryList = res.data.data.results
      this.otherCategoryList = res.data.data.results
    });
    // this.$store.dispatch('getConsultCurrenciesAction').then((res) => {
    //   this.currencyListLabel = res.data.data
    // });
    this.$store.dispatch('getDoctorConsultInfoAction').then((res) => {
      this.$nextTick(() => {
        this.isContentReady = true;
      })
      this.scheduleInfo = res.data.data;
      if (!this.scheduleInfo) return;
      this.updateFormState = true; 
      this.organization = this.scheduleInfo.organization;
      this.experience = this.scheduleInfo.experience;
      this.education = this.scheduleInfo.education;
      this.other_specialization = this.scheduleInfo.other_specialization;
      this.about_doctor = this.scheduleInfo.about_doctor;
      this.user_agreement = this.scheduleInfo.read_terms_1;
      this.onlineTeleconsulting = this.scheduleInfo.read_terms_2;
      this.consultFees = this.scheduleInfo.amount;
      const currency = {
        code:this.scheduleInfo.currency,
        name:"US Dollar"
      }
      this.currencyVal.push(currency);
      const category = {
        filter_key:this.scheduleInfo.profile.professional_filter_category,
        display_name:this.scheduleInfo.profile.professional_filter_name
      }
      this.category.push(category);
      this.disabledSomeFields = this.scheduleInfo.read_terms_2;
      this.license.number = this.scheduleInfo.license_value || '';
      this.license.countryCode = this.scheduleInfo.license_country_code || '';
      if (this.license.countryCode != null && this.license.countryCode != '') {
        this.license.countryIso = this.allCountriesList.filter(item => item.dialCode == this.license.countryCode)[0].iso2;
      }
      this.registrationPortal = this.scheduleInfo.license_portal || '';
      const languages = JSON.parse(this.scheduleInfo.language) || [];
      if (languages && languages.length > 0) {
        for (var i = 0; i < languages.length; i++) {
          this.languageProficiencyValue.push({ name: languages[i], code: languages[i] })
        }
      }
      this.weeklySchedule = this.scheduleInfo.schedule;
      this.weeklySchedulestr = "test";
      this.uploadLicenseFileName = this.getFilenameFromUrl(this.scheduleInfo.license_file);
      this.$store.dispatch('getESignedSignatureMethod').then((res)=>{
        this.uploadESignedLicenseFileName = this.getFilenameFromUrl(res.data.data.sign);
      })
    }).catch((error) => {
      this.isContentReady = true
    });
    for (var i = 0; i < allCountries.length; i++) {
      let c = allCountries[i];
      this.allCountriesList.push({
        name: c[0],
        iso2: c[1],
        dialCode: c[2],
        priority: c[3] || 0,
        areaCodes: c[4] || null
      });
    };
    this.getUserCountry();
    bus.$on('profileUpdatePopupStateBus', (data) => {
      this.profileUpdatePopupState = data
    });
    bus.$on('notEditableFieldsPopupStateBus', (data) => {
      this.notEditableFieldsPopupState = data
    });
  },
  methods: {
    validateAllSlots() {
      let isValid = true;
      for (const weekKey in this.weeklySchedule) {
        this.weeklySchedule[weekKey].forEach((slot, slotIndex) => {
          if (!this.validateTimeRange(weekKey, slotIndex)) {
            isValid = false;
          }
        });
      }
      return isValid;
    },
    validateTimeRange(weekKey, slotIndex) {
      const slot = this.weeklySchedule[weekKey][slotIndex];
      if (slot.start_time >= slot.end_time) {
        return false;
      } else {
        return true;
      }
    },
    getFilenameFromUrl(url) {
      if(!url) return;
      // Parse the URL into its components
      const urlObject = new URL(url);

      // Extract the path from the URL
      const path = urlObject.pathname;

      // Split the path into segments based on the last slash
      const segments = path.split('/');

      // The filename is the last segment of the path
      const filename = segments[segments.length - 1];

      return filename;
    },
    notEditableFieldsPopupOpenMethod(){
      this.notEditableFieldsPopupState = true;
      document.body.classList.add('popup_open_body');
    },
    profileUpdateAlertPopup(){
      this.profileUpdatePopupState = true;
      document.body.classList.add('popup_open_body');
    },
    addNewCustomCategory(newTag){
      const tag = {
        display_name: newTag,
        filter_key: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
      }
      this.otherCategoryList.push(tag)
      this.other_specialization.push(tag)
    },
    getCurrencyNameByCode(currencies, code) {
      // Find the currency object with the given code
      const currency = currencies.find(currency => currency.code == code);
      // Return the name if found, otherwise return an empty string or handle the error appropriately
      return currency ? currency.name : "";
    },
    transformSchedule(schedule) {
      const weeklyData = {};
      for (const week in schedule) {
        weeklyData[week] = schedule[week].map(entry => ({
          day: [entry.day], // Enclose day in an array
          start_time: entry.start_time,
          end_time: entry.end_time
        }));
      }
      const combinedData = [];
      const timeGroups = {};
      for (const week of Object.values(weeklyData)) {
        for (const entry of week) {
          const timeKey = `${entry.start_time}_${entry.end_time}`;
          if (!timeGroups[timeKey]) {
            timeGroups[timeKey] = [];
          }
          
          timeGroups[timeKey].push(...entry.day);
        }
      }
      for (const timeKey in timeGroups) {
        combinedData.push({
          days:timeGroups[timeKey],
          start_time: timeKey.split('_')[0],
          end_time: timeKey.split('_')[1]
        });
      }
      this.weeklyDayTimesValue = combinedData
      this.availableDayValue = this.weeklyDayTimesValue[0].days
      this.start_time = this.weeklyDayTimesValue[0].start_time
      this.end_time = this.weeklyDayTimesValue[0].end_time
    },
    handleUploadESignedLicenseFile(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.uploadESignedLicenseFile = event.target.files[0];
        this.uploadESignedLicenseFileName = this.uploadESignedLicenseFile.name
      } else {
        this.uploadESignedLicenseFileName = '';
      }
    },
    handleUploadLicenseFile(event) {
      if (event.target.files && event.target.files.length > 0) {
        this.uploadLicenseFile = event.target.files[0];
        this.uploadLicenseFileName = this.uploadLicenseFile.name
      } else {
        this.uploadLicenseFileName = '';
      }
    },
    chooseCountry(country) {
      this.license.countryIso = country.iso2;
      this.license.country = country.dialCode;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    clickedOutside() {
      this.open = false;
    },
    getUserCountry() {
      var self = this;
      fetch('https://ip2c.org/s')
        .then(function (response) {
          return response.text();
        })
        .then(function (text) {
          if (!text || "1" !== text[0])
            throw new Error("unable to fetch the country");
          self.license.countryIso = (text.substr(2, 2)).toLowerCase();
          self.license.countryCode = self.allCountriesList.filter(item => item.iso2 == self.license.countryIso)[0].dialCode;
        })
        .catch(function (error) {
          console.log('Request failed', error)
        });
    },
    getTimeDiffBtWStartandEndMethod(nowt, thent) {
      var now = moment(nowt).format('HH:mm:ss');
      var then = moment(thent).format('HH:mm:ss');
      var outputs = moment.utc(moment(then, "HH:mm:ss").diff(moment(now, "HH:mm:ss"))).format("HH:mm:ss")
      if (outputs > '03:59:59') {
        this.timeDiffValidation = false;
        return true;
      } else {
        this.timeDiffValidation = true;
        return false;
      }
    },
    addScheduleItemMethod() {
      for (var i = 0; i < this.availableDayValue.length; i++) {
        let dayObj = {
          day: this.availableDayValue[i],
          start_time: this.start_time,
          end_time: this.end_time,
        }
        this.availableDaySelectedArrValue.push(dayObj)
        if (!this.weeklySchedule.hasOwnProperty('week_1')) {
          this.weeklySchedule['week_1'] = [];
        }
        this.weeklySchedule['week_1'] = this.availableDaySelectedArrValue
      }
      // console.log(this.weeklySchedule)
    },
    consultFormBeforeSubmitMethod() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.validateAllSlots()) {
            this.showErrorsTimes = false;
          } else {
            this.showErrorsTimes = true;
            return;
          }
          this.submitted = true
          if(this.updateFormState){
            for (var i = 0; i < this.languageProficiencyValue.length; i++) {
              this.sendLanguageProficiencyValue.push(this.languageProficiencyValue[i].name)
            }
            let doctorAvaibleData = {
              schedule: this.weeklySchedule,
              language: JSON.stringify(this.sendLanguageProficiencyValue),
              amount: this.consultFees,
              currency: this.currencyVal.code,
              about_doctor: this.about_doctor,
              experience: this.experience,
              education: this.education,
              other_specialization: this.other_specialization,
              organization:this.organization
            }
            this.$store.dispatch('updateDoctorConsultInfoAction', doctorAvaibleData)
              .then((res) => {
                toast.success(res.data.msg);
                this.submitted = false;
                this.$router.push({name:'consultScheduleFormUpdateSuccess'})
              }).catch((err) => {
                toast.error(err.data.user_msg || err.data.msg);
                this.submitted = false
              })
          }else{
            for (var i = 0; i < this.languageProficiencyValue.length; i++) {
              this.sendLanguageProficiencyValue.push(this.languageProficiencyValue[i].name)
            }
            let doctorAvaibleData = {
              schedule: this.weeklySchedule,
              language: JSON.stringify(this.sendLanguageProficiencyValue),
              read_terms_1: this.user_agreement,
              read_terms_2: this.onlineTeleconsulting,
              amount: this.consultFees,
              currency: this.currencyVal.code,
              about_doctor: this.about_doctor,
              category_key: this.category.filter_key,
              experience: this.experience,
              education: this.education,
              other_specialization: this.other_specialization,
              organization:this.organization
            }
            if (this.registrationPortal != '') {
              doctorAvaibleData.license_portal = this.registrationPortal;
            }
            if (this.license.number != '') {
              doctorAvaibleData.license_value = this.license.number;
              doctorAvaibleData.license_country_code = this.license.countryCode;
            }
            this.$store.dispatch('postDoctorScheduleAction', doctorAvaibleData)
              .then((res) => {
                if (this.uploadLicenseFile) {
                  let formData = new FormData();
                  formData.append('license_file', this.uploadLicenseFile);
                  this.$store.dispatch('uploadLicenseFileOfDoctorMethod', formData)
                }
                if (this.uploadESignedLicenseFile) {
                  let formData = new FormData();
                  formData.append('sign', this.uploadESignedLicenseFile);
                  this.$store.dispatch('uploadESignedSignatureMethod', formData)
                }
                toast.success(res.data.msg);
                this.submitted = false;
                if((this.selfUsrInfoState && !this.selfUsrInfoState.city) || (this.selfUsrInfoState && !this.selfUsrInfoState.country) || (this.selfUsrInfoState && !this.selfUsrInfoState.address)){
                  this.profileUpdateAlertPopup();
                  return;
                }
                this.$router.push({name:'consultScheduleFormSuccess'})
              }).catch((err) => {
                toast.error(err.data.user_msg || err.data.msg);
                this.submitted = false
              })
          }
          return;
        }
      })
    },
  },
  watch:{
    weeklySchedule(newVal, oldVal){
      this.weeklySchedulestr = "test";
    },
    getSelfUsrInfoMethod() {
      return this.$store.getters.selfUserDetailGetters;
    },
  }
}
</script>
<style lang="scss" scoped>
.hiddenField{
  position:absolute;
  visibility:hidden;
  opacity:0;
  z-index:-1;
}
.doctor_consult_form_main {
  padding: 15px;
    @media only screen and (max-width:767px) {
      padding: 0px;
    }
  .weekly_wrapper_box {
    background: rgba(96, 96, 96, 0.08);
    border-radius: 5px;
    padding: 15px 30px;
    color: #fff;
    @media only screen and (max-width:767px) {
      padding: 10px;
    }

    .heading_f_weekly_slots {
        color: #ffd400;
        font-size: 20px;
        margin: 0;
        text-align: center;
        padding-bottom: 30px;
        @media only screen and (max-width:767px){font-size:18px; line-height:1.3; padding-bottom: 10px;}
    }
    .w_slots_exmp_txt,
    .w_slots_exmp{
        color: #cec7a7;
        font-size:16px;
        margin: 0;
    }
  }
  .doctor_consult_form_body {
    background: rgba(20, 26, 30, 0.85);
    max-width: 100%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 4px;
    min-height:calc(100vh - 200px);
    @media only screen and (max-width:767px) {padding: 15px;}

    label {
      font-size: 20px;
      color: #d6d6d6;
      margin-bottom: 0px;
      line-height: 1.3;
      @media only screen and (max-width:767px){font-size:16px;}
      &.w_slots_blurb {
          text-align: justify;
          // font-size: 13px;
      }
      &.disabled {
          position: relative;
          padding: 12px 15px;
          margin: 0;
          line-height: 1;
          border-radius: 4px;
          color: #707070;
          cursor: pointer;
          margin-bottom: 0px !important;
          word-break: break-all;
          width: 100%;
          font-size: 18px;
          background: #3d3d3d;
          opacity: 0.8;
          cursor: not-allowed;
          min-height: 42px;
      }
    }

    .form-control {
      height: auto;
      padding: 9px 15px;
      background: rgba(255, 255, 255, 0.1);
      border-color: transparent;
      font-size: 16px;
      color: #fff;
      font-family: 'MyriadProRegular', 'Lato', sans-serif;
      -webkit-transition: all 0.7s cubic-bezier(0.42, 1.14, 1, 10);
      transition: all 0.7s cubic-bezier(0.42, 1.14, 1, 1);
      box-shadow: none !important;
      &[disabled="disabled"]{
        background: #3d3d3d;
        opacity: 0.8;
        cursor: not-allowed;
     }
    }

    .btn-primary {
      border: none;
      background: rgba(255, 255, 255, 0.12);
      color: #ffd400;
      font-size: 18px;
      border-radius: 3px;
      min-width: 100px;
      padding: 8px 10px;

      &:hover {
        background: #87cefa;
        color: #000000;
      }
    }

    .invalid-feedback {
      font-size: 14px;
      line-height: 1;
      display: block;
    }

    .form-group {
      margin-bottom: 25px;
      @media only screen and (max-width:767px){margin-bottom:15px;}
    }

    .iti-flag {
      margin-right: 5px;
      margin-left: 5px;
    }

    .vue-tel-input {
      border: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-align: left;
      &.disabled{
        background: #3d3d3d;
        opacity: 0.8;
        cursor: not-allowed;
      }
      .selection {
        font-size: .8em;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
      }

      .dropdown-arrow {
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
        display: inline-block;
        color: #ffffff;
      }

      .dropdown {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        padding: 7px;
        padding-left: 0px;
        cursor: pointer;
        outline: 0;
        box-shadow: none;
        background: rgba(255, 255, 255, 0.1);
        border-radius: 4px 0px 0px 4px;
      }

      .dropdown-item {
        cursor: pointer;
        padding: 4px 15px;
        font-size: 18px;
        color: #ffc107;
        font-family: 'MyriadProRegular', 'Lato', sans-serif;

        strong {
          font-weight: normal;
        }

        &:hover {
          background: #2b76ff !important;
          color: #fff;
        }

        .iti-flag {
          display: inline-block;
          margin-right: 5px;
        }
      }

      .dropdown.open,
      .dropdown:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      .form-control {
        border-radius: 0 4px 4px 0;
      }
    }

    .accept_terms_label {
      margin: 0;
      display: flex;
      align-items: flex-start;
      cursor: pointer;

      .checkbox input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        visibility: hidden;
      }

      .checkbox span {
        display: inline-block;
        width: 25px;
        height: 25px;
        border: 2px solid #ffe400;
        vertical-align: top;
        border-radius: 3px;
        margin-top: 2px;
        line-height: 22px;
        text-align: center
      }

      .checkbox span .fa {
        color: #000000;
        font-size: 20px;
        opacity: 0;
      }

      .checkbox input[type="checkbox"]:checked+span {
        background-color: #ffe400;

        .fa {
          opacity: 1;
        }
      }

      .accept_terms_text {
        margin-left: 10px;

        a {
          color: #ffe400;
          text-decoration: none;

          &:hover {
            color: #87cefa;
          }
        }
      }
    }

    .upload_attachment_btn {
      label {
        position: relative;
        padding: 12px 15px;
        background: rgba(255, 255, 255, 0.1);
        margin: 0;
        line-height: 1;
        border-radius: 3px;
        color: #707070;
        cursor: pointer;
        margin-bottom: 0px !important;
        word-break: break-all;
        width: 100%;
        font-size: 18px;
        &[disabled="disabled"]{background: #3d3d3d !important;
        opacity: 0.8;
        cursor: not-allowed;}
      }

      input[type="file"] {
        position: absolute;
        opacity: 0;
        display: none;
      }
    }
  }
}
</style>
<style lang="scss">
.main_wrapper {
.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select,.multiselect--disabled{background:none !important;pointer-events: auto;cursor: not-allowed;  opacity: 1;
  .multiselect__tags{
  background: #3d3d3d !important;
  opacity: 0.8;
  cursor: not-allowed;
}}
}
</style>