<template>
    <div class="update_profile_info_popup">
        <div class="update_profile_info_inner_popup">
            <div class="row">
                <div class="col">
                    <h3>Profile Update</h3>
                </div>
            </div>
            <form @submit.prevent="updateUserInfoFormBeforeSubmit">
                <div class="form-group">
                    <label>Country <span class="star_required">*</span></label>
                    <select class="form-control text-capitalize" name="country" v-model="country"
                        v-validate="'required'" v-bind:class="{ 'is-invalid': errors.has('country') }"
                        data-vv-as="country" style="text-transform:capitalize;">
                        <option value="">Select Country</option>
                        <option :value="country.value" v-for="country in countryList" :key="country.value">{{
                            country.name }}
                        </option>
                    </select>
                    <small class="invalid-feedback" v-if="errors.has('country')">{{ errors.first('country') }}</small>
                </div>
                <div class="form-group">
                    <label>City <span class="star_required">*</span></label>
                    <input type="text" class="form-control" name="city" v-model="city" v-validate="'required'"
                        v-bind:class="{ 'is-invalid': errors.has('city') }" data-vv-as="city" />
                    <small class="invalid-feedback" v-if="errors.has('city')">{{ errors.first('city') }}</small>
                </div>
                <div class="form-group">
                    <label>Address <span class="star_required">*</span></label>
                    <textarea type="text" class="form-control" v-model="address" rows="3" v-validate="'required'"
                        v-bind:class="{ 'is-invalid': errors.has('address') }" name="address"
                        data-vv-as="address"></textarea>
                    <small class="invalid-feedback" v-if="errors.has('address')">{{ errors.first('address') }}</small>
                </div>
                <button type="submit" class="btn btn-primary mt-4" :disabled="submitted">Update</button>
            </form>
        </div>
    </div>
</template>

<script>
/*eslint-disable */
import {bus} from '@/main';  
import toast from '@/services/toast';
import countries from '@/services/country.json';
export default {
  name: 'profile-update-fields-popup',
  components: {},
  props: [],
  data () {
    return {
      country:'',
      city:'',
      submitted:false,
      countryList:[],
      country:'',
      city:'',
      address:'',
      submitted:false
    }
  },
  computed: {

  },
  mounted () {
    Object.keys(countries).map((key, index) => {
      this.countryList.push({
        name: countries[key],
        value: key.toLowerCase()
      })
    });
    this.countryList.sort((a, b) => (a.name > b.name) ? 1 : -1)
  },
  methods: {
    getSelfUserInfoAgainFun(){
      this.$store.dispatch('getSelfUserInfoApiFun').then((res) => {
        this.closeUpdateProfileInfoMethod();
      })
    },
    closeUpdateProfileInfoMethod() {
      bus.$emit('updatedProfilePopupBus', false);
      document.body.classList.remove('popup_open_body');
      this.$router.push({name:'consultScheduleFormSuccess'});
    },
    updateUserInfoFormBeforeSubmit(){
      this.$validator.validateAll().then((result) => {
        if(result){
          this.submitted = true;
          let data = {
            country:this.country,
            city:this.city,
            address:this.address
          }
         this.$store.dispatch('selfUserInfoUpdateAction', data).then((res) => {
          toast.success(res.data.msg);
          this.getSelfUserInfoAgainFun();
          this.submitted =  false
         }).catch((err) => {
          toast.error(err.data.msg);
          this.submitted =  false
         })
         return;
        }
      })
    },
  }
}
</script>


<style lang="scss" scoped>
.update_profile_info_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000;
    background:rgba(0, 0, 0, 0.92);
    height: 100vh;
    display: flex;
    align-items: center;
    .update_profile_info_inner_popup {background: #000;max-width:520px;margin: 0 auto;border-radius: 5px;
        box-shadow:3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        text-align: left;padding: 15px 20px;width:100%;}
    h3 {
        font-size: 24px;
        line-height:1.4;
        color: #efefef;
        font-weight: normal;
        margin-bottom:20px;
    }
    .form-group{margin-bottom:10px;}
    label{font-size:18px;color:rgba(255, 255, 255, 0.75);}
    .btn-primary{border:none;background: rgba(255, 255, 255, 0.12);color:#ffd400;font-size:18px;border-radius:3px;min-width:100px;padding:6px 10px;box-shadow:none !important;
    &:hover{background:#87cefa;color:#000000;}}
    .form-control{background:rgba($color: #ffffff, $alpha: 0.1);border:none;color:#ffffff;padding:9px 15px;height:auto;font-size: 18px;option{color:#000000;}&.custom_style_of_dob{background:none;padding:0px;}}
    .u_pro_popup_close .fa{font-size: 24px;
        color: red;
        cursor: pointer;
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -webkit-transition: all .5s ease;
        transition: all .5s ease;
        &:hover{color: #87cefa;}
    }
}
</style>